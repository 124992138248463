.modal-form form {
    display: flex;
    flex-direction: column;
}

.modal-appear {
    animation: appear .2s ease-in forwards;
    opacity: 0;
}


.modal-disappear {
    animation: disappear .2s ease-in forwards;
    opacity: 1;
}

@keyframes appear {
    100% {
        opacity: 1;

    }
}

@keyframes disappear {
    100% {
        opacity: 0;

    }
}

.modal-title {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-family: monospace;
}

.modal-container {
    width: 500px;
    height: auto;
    box-shadow: 0px 5px 15px black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;

    background-image: linear-gradient(45deg, #ede8fb,#fafce2  ) ;
    background-size: 400% 400% ;
    animation: gradient 5s  ease infinite ;
}

@keyframes gradient {
	0% {
		background-position: 0% 100%;
	}
	50% {
		background-position: 100% 0%;
	}
	100% {
		background-position: 0% 100%;
	}
}
.exit-button{
    margin-right: -450px;
}


.email {
    width: 75%
}

.inputs {
    display: flex;
    gap: 10px;
}

textarea:focus-within,
select:focus-within,
.inputs div:focus-within {
    border: 1px solid #a182ff;
}

.inputs input {
    line-height: 25px;

}

textarea {
    background-color: rgb(227, 227, 227);
    border-radius: 3px;
    font-family: monospace !important;
    font-weight: 500;

}

textarea:focus {
    color: black;
    font-size: 1rem;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 500;
    outline: none;
}

textarea::placeholder {
    font-size: 1rem;
    font-weight: 500;
    font-family: monospace;
    padding: .8rem;
    color: rgb(123, 122, 122) !important;
}

.inputs div,
input {
    display: flex;
    flex-direction: column;
    background-color: rgb(227, 227, 227);
    border-radius: 3px;
    margin-bottom: 10px;


}

.inputs input {
    margin: 0;
}

select,
.inputs input:focus {
    outline: none;
}

label {
    font-size: .7rem;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 500;
    padding-left: 5px;
    padding-top: 5px;
}

.inputs input {
    color: black;
    font-size: 1rem;
    font-family: monospace;
    
    padding-left: 5px;

}

.button-submit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  

}

@media only screen and (max-width: 650px) {
    .inputs {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .email {
        width: 100%
    }
    .modal-container {
        width: auto;
        height: auto;
        box-shadow: 0px 5px 15px black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 52px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 10px;
    
        background-image: linear-gradient(45deg, #ede8fb,#fafce2  ) ;
        background-size: 400% 400% ;
        animation: gradient 5s  ease infinite ;
    }
    .exit-button{
        margin-right: -310px;
        scale: 120%;
    }
    /* .button-submit {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: .5rem;
        margin-top: .5rem;
    
    } */
    
}
