body {
    scroll-behavior: smooth;
        
}
.navbar-blur {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.Navbar {
  width: 100%;
  height: 80px;
  display: flex;
}

.Navbar .leftSide {
  flex: 50%;
  display: flex;
  justify-content: left;
  align-items: center;
}

.Navbar .leftSide .links {
  max-height: 80px;
}

.Navbar .leftSide .links a {
  text-decoration: none;
  color: white;
  font-size: 25px;
  margin-left: 25px;
  font-family: 'Courier New', Courier, monospace;
}

.Navbar .leftSide #hamburger {
  display: none;
}

.Navbar .leftSide #hidden {
  display: flex;
}

.Navbar .rightSide {
  flex: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 25px;
}

@media only screen and (max-width: 850px) {
  .Navbar .leftSide .links a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 750px) {
  .Navbar .leftSide .links a {
    font-size: 15px;
  }
}

@media only screen and (max-width: 650px) {
  .Navbar .leftSide #hamburger {
    display: flex;
    margin-left: 20px;
  }

  .Navbar .leftSide .links {
    display: none;
  }

  .Navbar .leftSide #hidden {
    position: absolute;
    left: 0px;
    top: 80px;
    height: 230px;
    max-height: 230px;
    width: 100%;
    background-color: rgb(24 24 27);
    opacity: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  .Navbar .leftSide #hidden a {
    margin: 8px;
    font-size: 18px;
  }


}
