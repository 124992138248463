@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;


}

.main {
    width: 100%;
    height: 100%;
}


video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
}

.content {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    margin-top: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section {
    scroll-margin-top: 50px;
}



/* @media only screen and (max-width: 450px) {

    html,
    body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
    }
} */